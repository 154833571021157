import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import TextGradient from "../components/ui/text-gradient"
import ShapeDown from "./ui/shape-down"
import Feature from "./ui/feature"

const options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const type = node?.data?.target?.__typename || null

      if (type !== "ContentfulTextGradient") {
        return children
      }

      return <TextGradient>{node?.data?.target?.content}</TextGradient>
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1 className="text-white max-w-2xl mx-auto">{children}</h1>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className="mt-6 text-lg text-white">{children}</p>
    },
  },
}

const HeroCompany = () => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPageCompany {
          heroContent {
            raw
            references {
              ... on ContentfulTextGradient {
                contentful_id
                __typename
                content
              }
            }
          }
          featuredImage {
            gatsbyImageData
            title
          }
        }
      }
    `
  )

  const content = data?.heroContent || ""
  const img = data?.featuredImage || ""

  return (
    <ShapeDown>
      <section className="feature relative z-10">
        <Feature
          img={img.gatsbyImageData}
          imgAlt={img.title}
          imgSide="top-right"
          alignY="center">
          {content && renderRichText(content, options)}
        </Feature>
      </section>
    </ShapeDown>
  )
}

export default HeroCompany
