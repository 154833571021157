import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "../components/ui/grid"
import HeroCompany from "../components/hero-company"
import CtaEnterprise from "../components/cta-enterprise"
import Contact from "../components/contact"
import TextGradient from "../components/ui/text-gradient"
import Button from "../components/ui/button"

const options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const type = node?.data?.target?.__typename || null

      if (type !== "ContentfulTextGradient") {
        return children
      }

      return <TextGradient>{node?.data?.target?.content}</TextGradient>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className="text-xl">{children}</p>
    },
  },
}

const Company = ({ location }) => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPageCompany {
          title
          description
          featuredImage {
            gatsbyImageData
            resize(width: 512, quality: 80) {
              src
            }
          }
          jumbotron {
            raw
            references {
              ... on ContentfulTextGradient {
                contentful_id
                __typename
                content
              }
            }
          }
          jumbotronButton {
            title
            url
          }
          locationsTitle
          locations {
            name
            city
            id
            address {
              address
            }
            image {
              gatsbyImageData
            }
          }
        }
      }
    `
  )

  const title = data?.title || ""
  const description = data?.description || ""
  const thumbnail = data?.featuredImage?.resize?.src || ""
  const jumbotron = data?.jumbotron || ""
  const jumbotronBtn = data?.jumbotronButton || ""
  const locationsTitle = data?.locationsTitle || ""
  const locations = data?.locations || []

  return (
    <Layout
      location={location}
      hero={<HeroCompany />}
      beforeFooter={<CtaEnterprise />}>
      <Seo
        title={title}
        description={description}
        image={thumbnail}
        url={location.href}
      />

      <div className="py-16 text-center lg:py-12">
        <Container className="max-w-4xl">
          {jumbotron && renderRichText(jumbotron, options)}

          <div className="mt-10 text-center">
            <Button label={jumbotronBtn?.title} to={jumbotronBtn?.url} />
          </div>
        </Container>
      </div>

      <Contact title={locationsTitle} locations={locations} />
    </Layout>
  )
}

export default Company
