import * as React from "react"
import { useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { navigate } from "gatsby"

import { errorStyles, selectStyles, encode } from "../helpers"

import ButtonWithLoader from "./ui/button-with-loader"
import ErrorMessage from "./ui/error-message"

const FormContact = ({ contactLocation }) => {
  const [alert, setalert] = React.useState(null)
  const [isLoading, setisLoading] = React.useState(false)
  const formEl = React.useRef(null)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data, e) => {
    e.preventDefault()
    const form = e.target

    setisLoading(true)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...data,
      }),
    })
      .then(() => {
        navigate("/contact-us-thank-you")
      })
      .catch(error => {
        setalert("Form could not be submitted. Please try again.")
        setisLoading(false)
        formEl.current.reset()
        console.log(error)
      })
  }

  const topicsOptions = [
    { value: "Talk to a Sales Rep", label: "Talk to a Sales Rep" },
    { value: "Technical Support", label: "Technical Support" },
    { value: "Billing", label: "Billing" },
    { value: "Partnership", label: "Partnership" },
    { value: "Marketing", label: "Marketing" },
    { value: "Enterprise Deployment", label: "Enterprise Deployment" },
    { value: "General", label: "General" },
  ]

  return (
    <div className="relative">
      {alert && <p>{alert}</p>}
      <form
        ref={formEl}
        name="contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="my-12"
        onSubmit={handleSubmit(onSubmit)}>
        <div hidden>
          <label>
            Don’t fill this out: <input name="bot-field" />
          </label>
        </div>

        <input
          type="hidden"
          name="contactLocation"
          defaultValue={contactLocation}
          {...register("contactLocation")}
          readOnly
        />

        <fieldset className="form-columns-2">
          <div>
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              style={errors.firstName && errorStyles}
              {...register("firstName", { required: true, maxLength: 80 })}
            />
            {errors.firstName?.type === "required" && <ErrorMessage />}
          </div>

          <div>
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              style={errors.lastName && errorStyles}
              {...register("lastName", { required: true, maxLength: 80 })}
            />
            {errors.lastName?.type === "required" && <ErrorMessage />}
          </div>
        </fieldset>

        <fieldset>
          <label htmlFor="email">Email address</label>
          <input
            type="email"
            name="email"
            id="email"
            style={errors.email && errorStyles}
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
          />
          {errors.email?.type === "required" && <ErrorMessage />}
        </fieldset>

        <fieldset>
          <label htmlFor="phoneNumber">Phone number</label>
          <input
            type="number"
            name="phoneNumber"
            id="phoneNumber"
            style={errors.phoneNumber && errorStyles}
            {...register("phoneNumber", { required: true })}
          />
          {errors.phoneNumber?.type === "required" && <ErrorMessage />}
        </fieldset>

        <fieldset>
          <label htmlFor="companyName">Company name</label>
          <input
            type="text"
            name="companyName"
            id="companyName"
            style={errors.companyName && errorStyles}
            {...register("companyName", { required: true })}
          />
          {errors.companyName?.type === "required" && <ErrorMessage />}
        </fieldset>

        <fieldset>
          <label htmlFor="topic">Pick a Topic</label>
          <Controller
            name="topic"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={topicsOptions}
                styles={selectStyles}
              />
            )}
          />
          {errors.topic?.type === "required" && <ErrorMessage />}
        </fieldset>

        <fieldset>
          <label htmlFor="message">Message</label>
          <textarea
            type="text"
            name="message"
            id="message"
            style={errors.message && errorStyles}
            {...register("message", { required: true })}
          />
          {errors.message?.type === "required" && <ErrorMessage />}
        </fieldset>

        <ButtonWithLoader label="Submit" isLoading={isLoading} />
      </form>
    </div>
  )
}

export default FormContact
