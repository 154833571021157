import * as React from "react"
import Modal from "react-modal"

import { Container } from "./ui/grid"
import Location from "./ui/location"
import FormContact from "./form-contact"
import Social from "./ui/social"
import TextGradient from "./ui/text-gradient"

import Times from "../svg/times.svg"

const Contact = ({ title, locations }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [contactLocation, setcontactLocation] = React.useState(null)
  const handleOpenModal = e => {
    setIsOpen(true)
    setcontactLocation(e.target.getAttribute("title"))
  }
  const handleCloseModal = () => setIsOpen(false)
  Modal.setAppElement("#___gatsby")

  return (
    <div id="contact" className="my-12 md:my-32">
      <Container>
        {title && (
          <h2 className="mb-12 md:text-center">
            <TextGradient>{title}</TextGradient>
          </h2>
        )}

        {locations &&
          locations.map(item => (
            <Location
              key={item?.id}
              city={item?.city}
              office={item?.name}
              address={item?.address?.address}
              image={item?.image?.gatsbyImageData}
              onClick={e => handleOpenModal(e)}
            />
          ))}

        <div className="my-12 max-w-2xl mx-auto text-blue">
          <Social size="lg" />
        </div>
      </Container>

      <Modal
        isOpen={modalIsOpen}
        contentLabel="Contact form modal"
        className="modal"
        overlayClassName="overlay"
        onRequestClose={() => handleCloseModal()}>
        <div className="sticky z-10 top-0 left-0 w-full bg-gray-light">
          <div className="flex max-w-xl mx-auto p-4">
            <h2 className="mb-0">
              <TextGradient>Contact us</TextGradient>
            </h2>

            <button
              onClick={() => handleCloseModal()}
              className="ml-auto focus:outline-none">
              <span className="sr-only">Close</span>{" "}
              <Times className="text-gray-dim opacity-40" />
            </button>
          </div>
        </div>

        <div className="max-w-xl mx-auto pb-20 px-4">
          <FormContact contactLocation={contactLocation} />
        </div>
      </Modal>

      <form data-netlify="true" name="contact">
        <label className="hidden" htmlFor="contactLocation">
          Branch Location:
        </label>
        <input type="hidden" name="contactLocation" />

        <label className="hidden" htmlFor="firstName">
          First Name:
        </label>
        <input type="hidden" name="firstName" />

        <label className="hidden" htmlFor="lastName">
          Last Name:
        </label>
        <input type="hidden" name="lastName" />

        <label className="hidden" htmlFor="email">
          Email address:
        </label>
        <input type="hidden" name="email" />

        <label className="hidden" htmlFor="phoneNumber">
          Phone Number:
        </label>
        <input type="hidden" name="phoneNumber" />

        <label className="hidden" htmlFor="topic">
          Topic:
        </label>
        <input type="hidden" name="topic" />

        <label className="hidden" htmlFor="message">
          Message:
        </label>
        <input type="hidden" name="message" />
      </form>
    </div>
  )
}

export default Contact
