import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const location = ({ city, office, address, image, onClick }) => {
  return (
    <div className="flex flex-wrap md:my-4 offset-margins">
      <div className="w-full lg:w-2/5 flex items-center bg-gray-light">
        <div className="w-full py-8 px-4 md:p-12 md:text-center lg:text-left">
          <h3 className="mb-6">{city}</h3>

          <div className="text-gray-dim font-medium">{office}</div>

          <div>{address}</div>

          <button
            onClick={onClick}
            title={city}
            className="btn btn-outlined-blue mt-6 w-full md:w-auto">
            Contact us
          </button>
        </div>
      </div>

      <div className="w-full lg:w-3/5">
        <GatsbyImage image={image} alt={city} />
      </div>
    </div>
  )
}

export default location
